.profile-container {
  height: 100%;
  flex-grow: 1;
}

.profile-user-info {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.profile-season-summary {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

.profile-season-summary-title {
  margin-bottom: 15px !important;
}

.season-summary-data-zone {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 450px;
}

.season-summary-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-historical {
  margin-bottom: 60px;
}

.profile-stats-filters {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.profile-club-table {
  background-color: var(--zone-background-color);
  border-radius: 12px;
  padding-bottom: 25px;
}

.profile-club-table-icon {
  width: 20px;
  height: 20px;
  fill: var(--color-light);
}

.profile-club-table-icon-card {
  width: 18px;
  height: 18px;
}

.profile-sub-title {
  margin-bottom: 15px !important;
}

@media (min-width: 0px) {
  .profile-container {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (min-width: 960px) {
  .profile-container {
    padding-left: 8%;
    padding-right: 8%;
  }
}
