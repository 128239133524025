@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600');

:root {
  --menu-width: 250px;
  --top-bar-height: 56px;
  --color-primary: #45d69f;
  --color-light: #f4f4f4;
  --color-pink: #e92163;
  --color-red: #fc3c2e;
  --color-yellow: #EDA247;
  --color-blue: #25a5e6;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Modal - Start */
.modal {
  max-height: 400px;
}

.modal-container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0;
}

@media (max-width: 960px) {
  .modal-container {
    padding: 20px;
  }
}

.modal-btn {
  margin-top: 30px !important;
  height: 160px;
  min-width: 70% !important;
  width: max-content;
  max-width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/* Modal - End */

/* Input - End */
.input-inline {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.input {
  margin-bottom: 10px !important;
}

.input-right {
  margin-left: 10px !important;
}
/* Input - End */

/* Menu List - Start */
.list-icon-zone {
  display: flex;
  justify-content: center;
}

.list-icon {
  width: 20px;
  height: 20px;
  fill: var(--color-dark);
  margin-right: 8px;
}
/* Menu List - End */

.yellow-card {
  fill: var(--color-yellow) !important;
}

.red-card {
  fill: var(--color-red) !important;
}