.fab.MuiFab-root {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 999;
  background-color: var(--color-primary);
  color: var(--color-dark);
  border-radius: 50%;
}

.fab.MuiFab-root:hover {
  background-color: var(--color-primary);
  filter: brightness(90%);
}

.fab-skeleton-wrapper {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 999;
}

.fab-skeleton {
  width: 56px !important;
  height: 56px !important;
}