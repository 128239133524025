:root {
  --color-light: #f4f4f4;
  --textfield-color: #e92163;
  --color-yellow: #EDA247;
  --color-blue: #2a353a;
  --zone-background-color: #1f2126;
  --zone-background-color-2: #242834;
  --button-width: 100px;
 --textfield-color: #e92163;
}

.cardZone {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centre verticalement */
  align-items: center; /* Centre horizontalement */
  background-color: var(--zone-background-color);
  border-radius: 25px;
  list-style-type: none;
  box-sizing: border-box;
  padding: 30px;
  height: 100%;
}

.titleZone {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleIconZone {
  width: 28px;
  height: 28px;
  padding: 15px;
  background-color: var(--zone-background-color-2);
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleIcon {
  width: 28px;
  height: 28px;
  fill: var(--color-primary);
}

.btn {
  margin-top: 30px;
}