:root {
  --zone-background-color: #1f2126;
  --zone-background-color-2: #242834;
  --button-width: 100px;
  --color-dark: #242834;
}

.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-grow: 1;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 3%;
  padding-right: 3%;
}

.auth-container-grid {
  width: 100%;
  display: flex;
  align-items: center;
}

@media (min-width: 600px) {
  .auth-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 900px) {
  .auth-container {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media (min-width: 1280px) {
  .auth-container {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.auth-form-btn {
  width: 100%;
  margin-top: 35px !important;
}

.auth-input {
  margin-bottom: 15px !important;
}

.auth-last-input {
  margin-bottom: 0;
}

.auth-input-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.auth-input-right {
  margin-left: 15px !important;
}

.auth-text-zone {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 900px) {
  .auth-form {
    flex: 1;
  }
}

.auth-image {
  height: 100%;
}

.auth-form-zone {
  background-color: #1a1919;
  border-radius: 25px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 2 * 20px);
  padding: 20px;
}

@media (min-width: 600px) {
  .auth-form-zone {
    max-width: 450px;
    width: calc(100% - 2 * 25px);
    padding: 25px;
  }
}

@media (min-width: 1280px) {
  .auth-form-zone {
    max-width: 450px;
    width: calc(100% - 2 * 25px);
    padding: 25px;
  }
}

.auth-sign-form-zone {
  background-color: #1a1919;
  border-radius: 25px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 2 * 20px);
  padding: 20px;
}

@media (min-width: 600px) {
  .auth-sign-form-zone {
    max-width: 450px;
    width: calc(100% - 2 * 25px);
    padding: 25px;
  }
}

.auth-sign-form-zone-2 {
  background-color: #1a1919;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: calc(100% - 2 * 20px);
  padding: 20px;
}

@media (min-width: 600px) {
  .auth-sign-form-zone-2 {
    max-width: 450px;
    width: calc(100% - 2 * 25px);
    padding: 25px;
  }
}

.auth-form-zone2-label {
  display: flex;
  flex-wrap: nowrap;
}

.auth-label-btn {
  all: unset;
  cursor: pointer;
  margin-left: 8px;
}

.auth-label-btn-label:hover,
.auth-label-btn-label:active,
.auth-label-btn-label:focus {
  filter: brightness(90%);
}

.auth-label-btn-wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: right;
  width: 100%;
}

.auth-error-mandatory-fields {
  margin-left: 20px;
}