/* Row Player */
:root {

  --color-primary-transparent: rgba(69, 214, 159, 0.4);
  --color-light: #f4f4f4;
  --color-pink: #e92163;
  --color-pink-transparent: rgba(233, 33, 99, 0.4);
  --color-blue: #25a5e6;
  --color-blue-transparent: rgba(37, 165, 230, 0.4);
  --color-purple: #9d44fd;
  --color-purple-transparent: rgba(157, 68, 253, 0.4);
  --color-orange: #f2a624;
  --color-orange-transparent: rgba(242, 166, 36, 0.4);
  --color-dark: #242834;
  --color-gold: #DFA831;
  --color-silver: #94A9AF;
  --color-bronze: #B8885B;
  --zone-background-color: #1f2126;
  --button-width: 100px;
  --menu-width: 250px;
  --border-radius: 18px;
}

.row-player-photo-container {
  position: relative;
  display: inline-block;
}

.row-player-photo {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: 1px solid var(--color-primary);
  margin-right: 10px;
}

.row-player {
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-player-name {
  display: flex;
  justify-content: center;
  text-align: center;
}

.row-player-stat {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
}

.row-player-stat-inner {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row-player-stat-value {
  font-size: 18px;
  font-weight: bold;
  color: var(--color-light);
  margin-bottom: 4px;
  padding: 4px 8px;
  width: 22px;
  height: 25px;
  display:flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  background-color: var(--color-primary);
}

.row-player-stat-label {
  font-size: 12px;
  color: var(--color-light);
}

.medal {
  position: absolute;
  bottom: -5px;
  right: 5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--color-light); 
}

.gold {
  fill: var(--color-gold);
}

div.gold {
  background-color: var(--color-gold);
}

.silver {
  fill: var(--color-silver);
}

div.silver {
  background-color: var(--color-silver);
}

.bronze {
  fill: var(--color-silver);
}

div.bronze {
  background-color: var(--color-bronze);
}

div.rating-high {
  background-color: var(--color-primary);
}

div.rating-medium {
  background-color: var(--color-orange);
}

div.rating-low {
  background-color: var(--color-pink);
}