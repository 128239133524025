:root {
  --bg:  #242526;
  --bg-accent: #484a4d;
  --text-color: #f4f4f4;
  --nav-size: 50px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;;
}

/* Top Navigation Bar */

/* <nav> */
.navbar {
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1rem;
  border-bottom: var(--border);
}

/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

/* <li> */
.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button:active, 
.icon-button:focus {
  background-color: #232e2f;
}

.icon-button svg { 
  fill: var(--text-color);
  stroke: var(--text-color);
  width: 18px;
  height: 18px;
}

.icon-button:focus svg { 
  fill: #45d69f;
  stroke: #45d69f;
}

.menu {
  width: 100%;
}

.menu-item {
  height: 45px;
  width: 300px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: var(--speed);
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 0rem;
  padding-bottom: 0rem;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  cursor: pointer;
}

.menu-item .icon-left {
  margin-right: 6px;
}

.menu-item .icon-button {
  margin-right: 6px;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #525357;
}

.icon-left {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 50%;
  padding: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-left svg { 
  fill: var(--text-color);
  stroke: var(--text-color);
  width: 18px;
  height: 18px;
  pointer-events: none;
}

.icon-right {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.icon-right svg {
  width: 16px;
  height: 16px;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-secondary-exit {

}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}