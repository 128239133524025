.row-player-photo-container {
  position: relative;
  display: inline-block;
}

.row-player-photo {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: 1px solid var(--color-primary);
  margin-right: 10px;
}

.row-player {
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-player-right {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0;
  margin-left: auto;
  gap: 10px;
}

.row-player-name {
  display: flex;
  justify-content: center;
  text-align: center;
}

.row-player-position {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-light);
  margin-bottom: 4px;
  padding: 6px 12px;
  width: 22px;
  align-self: flex-start;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  background-color: var(--color-dark);
}

.row-player-remove-btn {
  width: 12px;
  height: 12px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  stroke: var(--color-light);
  border-radius: 50%;
  background-color: var(--color-pink);
  transition: filter 0.2s ease;
  margin-top: -20px;
}

.row-player-remove-btn:hover {
  filter: brightness(90%);
}