.top-container {
  padding-top: 15px;
  position: sticky !important;
  top: calc(var(--top-bar-height));
  z-index: 999;
  background-color: var(--zone-background-color-2);
}

.filters-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  padding: 20px;
  border-radius: 20px !important;
  background-color: var(--zone-background-color) !important;
  box-sizing: border-box !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}