.container {
  height: 100%;
}

.chart-container {
  width: 100%;
  overflow-x: auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.chart-wrapper {
  width: 100%;
  height: 100%;
  min-width: 700px;
  display: inline-block;
}

.chart-wrapper canvas {
  width: 100%;
  height: 100%;
  max-height: 300px !important;
}

.stats-title {
  margin-bottom: 30px !important;
}

.stats-zone {
  width: 100%;
  background-color: var(--zone-background-color);
  border-radius: var(--border-radius);
  list-style-type: none;
  min-height: 200px;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.stats-content {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.stats-content-inner {
  display: flex;
  width: 100%;
  max-width: 500px;
  flex-direction: column;
}

.stats-divider {
  height: 1px;
  background-color: var(--color-dark);
  border-radius: 50px;
  margin-top: 8px;
  align-self: center;
}

.stats-content-inner > div:not(:last-child) .stats-divider {
  margin-bottom: 8px;
}

.stats-content-inner > div:last-child .stats-divider {
  margin-bottom: 0;
}

.stats-title {
  margin-bottom: 30px;
}

.stats-elmt-1 {
  max-height: 300px;
  max-width: 100%;
}

.stats-elmt-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 80px;
}

.stats-elmt-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
}

.stats-elmt-4 {
  height: 100%;
  max-height: 950px !important;
  max-width: 100%;
}

.stats-series {
  color: var(--color-primary);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 3px solid var(--color-primary);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Result */
.stats-result {
  margin-left: 5px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

div.stats-result-win {
  background-color: var(--color-primary);
}

div.stats-result-draw {
  background-color: var(--color-orange);
}

div.stats-result-loss {
  background-color: var(--color-pink);
}

div.stats-result-empty {
  background-color: var(--color-dark);
}