:root {
  --light-color: #f4f4f4;
  --footer-padding: 50px;
}

.footer {
  position: relative;
  background-color: #1f2126;
  padding: var(--footer-padding);
}

.footer-title {
  color: var(--light-color) !important;
  margin-bottom: 10px !important;
}

.footer-text {
  color: var(--light-color) !important;
  margin-bottom: 10px !important;
  cursor: pointer !important;
  display: inline-block;
}

.footer-text:hover {
  opacity: 0.8 !important;
}

.language-button {
  background-color: transparent;
  border: none;
  color: var(--light-color);
  margin-right: 10px;
  cursor: pointer;
}

.language-button:hover {
  text-decoration: underline;
}

.footer-logo-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.footer-logo {
  max-width: 40px;
  margin-right: 10px;
  height: auto;
}