.terms-and-conditions-container {
  border-radius: 8px;
  margin: auto;
  max-width: 800px;
  padding-left: 3%;
  padding-right: 3%;
}

.terms-and-conditions-title {
  margin-bottom: 30px !important;
  color: var(--color-light);
  width: 100% !important;
  display: flex;
  justify-content: center;
  font-size: 1.5em !important;
  font-weight: bold !important;
  line-height: 1.2 !important;
}

@media (min-width: 600px) {
  .terms-and-conditions-title {
    font-size: 1.6em !important;
  }
}

@media (min-width: 900px) {
  .terms-and-conditions-title {
    font-size: 1.8em !important;
  }
}


.terms-and-conditions-content {
  margin-top: 16px;
}

.terms-and-conditions-subtitle {
  margin-top: 16px !important;
  font-weight: 600;
}

.terms-and-conditions-text {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.54);
}

.terms-and-conditions-sectionTitle {
  margin-top: 35px !important;
  margin-bottom: 20px !important;
}

.terms-and-conditions-section-content-highlighted {
  color: var(--color-primary);
  font-weight: 600;
}

.terms-and-conditions-section-content {
  font-weight: 400 !important;
}

.terms-and-conditions-items-list {
  padding-left: 10px;
}

.terms-and-conditions-item {
  position: relative;
  padding-left: 15px;
  margin-bottom: 8px;
}

.terms-and-conditions-date {
  margin-bottom: 30px !important;
}

.terms-and-conditions-bullet {
  position: absolute;
  left: -10px;
  top: -16px;
  font-size: 3em;
  color: var(--zone-background-color);
  line-height: 1;
}

.terms-and-conditions-section-content {
  line-height: 1.5;
}