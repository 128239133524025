/* Variables pour les couleurs et les constantes */
:root {
  --top-bar-height: 56px;
  --light-color: #f4f4f4;
  --purple-color: #9d44fd;
  --dark-color: #242834;
  --primary-color: #45d69f;
  --background-color: #181a1e;
  --home-background-color: #181a1e;
}

/* Conteneur principal */
.home-container {
  width: 100%;
  margin-top: calc(-30px - var(--top-bar-height));
  margin-bottom: -15px;
  overflow: hidden;
}

/* Content - breakpoints */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--home-background-color);
  padding-left: 3%;
  padding-right: 3%;
}

/* Functionalities */
.functionalities {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (min-width: 0px) {
  .functionalities:not(:last-child) {
    margin-bottom: 50px;
  }
}

@media (min-width: 900px) {
  .functionalities:not(:last-child) {
    margin-bottom: 30px;
  }
}

/* Background Image */
.background-image-container {
  position: relative;
  min-height: calc(100vh - var(--top-bar-height));
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 5%;
  width: calc(100% - 10%);
}

/* Background Image - with brightness filter */
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../assets/images/home-photo.jpg');
  background-size: cover;
  background-position: center;
  filter: brightness(55%);
  z-index: -1;
}

@media (min-width: 0px) {
  .background-image-container {
    min-height: calc(100vh - 26px);
  }
}

@media (min-width: 600px) {
  .background-image-container {
    min-height: calc(100vh - calc(var(--top-bar-height) + 10px));
  }
}

@media (min-width: 900px) {
  .background-image-container {
    min-height: calc(100vh - calc(var(--top-bar-height) + 31px));
  }
}

/* Home Content */
.home-title {
  font-size: 28px !important;
  font-weight: 900 !important;
  margin-bottom: 30px !important;
}

.home-content {
  max-width: 80%;
}

@media (min-width: 600px) {
  .home-content {
    max-width: 75%;
  }

  .home-title {
    font-size: 24px !important;
    margin-bottom: 22px !important;
  }
}

@media (min-width: 900px) {
  .home-content {
    max-width: 65%;
  }

  .home-title {
    font-size: 24px !important;
    margin-bottom: 22px !important;
  }
}

@media (min-width: 1200px) {
  .home-content {
    max-width: 55%;
  }

  .home-title {
    font-size: 28px !important;
    margin-bottom: 30px !important;
  }
}

/* Title & text */
.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.title-label {
  font-size: 24px !important;
  font-weight: 900 !important;
}

.title-bar {
  width: 70px;
  height: 2px;
  background-color: var(--light-color);
  border-radius: 5px;
  margin-top: 5px;
}

.sub-title {
  font-size: 20px !important;
  margin-bottom: 20px !important;
  font-weight: 900 !important;
}

.text {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.text1 {
  color: var(--light-color);
}

.text2 {
  color: var(--dark-color);
}

@media (min-width: 600px) {
  .title {
    margin-bottom: 35px;
  }

  .title-label {
    font-size: 24px !important;
  }

  .sub-title {
    font-size: 20px !important;
  }

  .text {
    font-size: 15px !important;
  }
}

@media (min-width: 900px) {
  .title {
    margin-bottom: 50px;
  }
  .title-label {
    font-size: 28px !important;
  }

  .sub-title {
    font-size: 22px !important;
  }

  .text {
    font-size: 14px !important;
  }
}

/* Specific content classes */
.content1 {
  /* Style spécifique pour le contenu 1 */
}

.content2 {
  /* Style spécifique pour le contenu 2 */
}

.title1 {
  color: var(--primary-color) !important;
}

.title2 {
  color: var(--purple-color) !important;
}

/* Phone Image */
.phone-img-container {
  overflow: hidden;
  height: 300px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.phone-img {
  width: 125%;
  max-width: calc(400px + 50px);
  margin: 0 auto;
  display: block;
}

@media (max-width: 599px) {
  .phone-img {
    transform: translateX(0%) translateY(-16%) rotate(-20deg);
  }
}

@media (min-width: 600px) {
  .phone-img {
    transform: translateX(0%) translateY(-16%) rotate(-20deg);
  }
}

@media (min-width: 900px) {
  .phone-img {
    transform: translateX(-5%) translateY(-16%) rotate(-20deg);
  }
}

@media (min-width: 1200px) {
  .phone-img {
    transform: translateX(0%) translateY(-16%) rotate(-20deg);
  }
}

/* General image */
.image {
  width: 100%;
  max-width: 400px;
}

/* Install button styles */
.installBtnWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.installBtn {
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  z-index: 1000;
  padding: 8px;
}

/* Timeline section */
.timeline {
  position: relative;
  margin: 20px 0;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.section-slim {
  max-width: 900px;
}

.section-large {
  max-width: 1500px;
}

.section-content {
  align-items: stretch;
  justify-content: center;
}

@media (min-width: 600px) {
  .section-content {
    justify-content: flex-start;
  }
}

.step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-circle {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
}

.step-line {
  width: 2px;
  border-radius: 2px;
  height: 40px;
  background-color: var(--light-color);
  margin-bottom: 8px;
  margin-top: 8px;
}

.step-text {
  font-size: 18px;
  color: var(--light-color);
  font-weight: 500;
  margin-left: 30px;
}

.step-container {
  display: flex;
  flex-direction: row;
}

.step-icon {
  width: 20px;
  height: 20px;
  fill: var(--light-color);
}

.step-menu-icon {
  width: 24px;
  height: 24px;
}

/* Counter label */
.counter-label {
  display: inline !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  font-weight: 700 !important;
  text-align: center !important;
  max-width: 550px !important;
  line-height: 1.6 !important;
  font-size: 22px !important;
  margin-bottom: 20px !important;
}

@media (min-width: 900px) {
  .counter-label {
    font-size: 26px !important;
    margin-bottom: 30px !important;
  }
}

.counter-label-span {
  color: var(--primary-color) !important;
}

.counter-app-logo {
  margin-right: 10px;
  width: 45px;
  height: 45px;
}

@media (min-width: 900px) {
  .counter-app-logo {
    width: 70px;
    height: 70px;
  }
}

.counter-app-name {
  font-weight: 700 !important;
  color: var(--light-color) !important;
  font-size: 24px !important;
}

@media (min-width: 900px) {
  .counter-app-name {
    font-size: 28px !important;
  }
}

/* Fade in animation */
.fade-in-box {
  opacity: 0;
  transform: translateY(200px);
  transition: opacity 1.3s ease-out, transform 0.6s ease-out;
}

.fade-in-box.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Card */
.card-wrapper {
  display: table;
  width: 100%;
  max-width: 550px;
}

.card-small {
  background-color: var(--dark-color);
  border-radius: 25px;
  list-style-type: none;
  box-sizing: border-box;
  padding: 20px;
  background-image: url('../../assets/images/base-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: table-cell;
  border: 1px solid #f4f4f429;
  list-style-type: none;
}

.card-small-title {
  font-size: 18px !important;
  margin-bottom: 20px !important;
  font-weight: 900 !important;
}

.card-icon {
  fill: var(--purple-color);
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
}

/* Pitch Card */
.pitch-card-container {
  position: relative;
  width: 100%;
  max-width: 600px;
}

.pitch-card-content {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 10 !important;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 10000;
}

.pitch-card-content-inner {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10000;
}

.pitch-card-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.player {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(30, 29, 29, 0.8);
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  border: 2px solid #242834;
}

.player-1 {
  grid-area: 5 / 3;
  transform: translateY(45%);
}

/* Défenseurs - Joueurs 3 et 2 légèrement plus hauts */
.player-3 {
  grid-area: 4 / 1;
  transform: translateY(-45%);
}
.player-2 {
  grid-area: 4 / 5;
  transform: translateY(-45%);
}
.player-4 {
  grid-area: 4 / 2;
  transform: translateY(60%);
}
.player-5 {
  grid-area: 4 / 4;
  transform: translateY(60%);
}

/* Milieu - Joueur 6 au centre sur sa propre ligne */
.player-6 { 
  grid-area: 3 / 3; /* Place le joueur 6 au centre sur la 3ème ligne */
}

/* Milieu - Joueurs 7 et 8 au-dessus de 6 */
.player-10 { grid-area: 2 / 2; } /* Milieu central gauche */
.player-8 { grid-area: 2 / 4; } /* Milieu central droit */

/* Attaquants */
.player-11 {
  grid-area: 1 / 1;
  transform: translatex(60%);
}
.player-7 {
  grid-area: 1 / 5;
  transform: translatex(-60%);
}
.player-9 {
  grid-area: 1 / 3;
  transform: translateY(-45%);
}

.pitch-card {
  background-color: var(--dark-color);
  border-radius: 25px;
  list-style-type: none;
  box-sizing: border-box;
  padding: 20px;
  display: table-cell;
  border: 1px solid #f4f4f429;
  width: 100%;
  max-width: 700px;
  min-height: 380px;
  position: relative;
  overflow: hidden;
  background-image: url('../../assets/images/pitch.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  align-items: center; 
}

.pitch-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(46, 139, 104, 0.8) 80%);
  z-index: 2;
}

.pitch-card * {
  position: relative;
  z-index: 3;
}

.pitch-card-title {
  font-size: 18px !important;
  margin-bottom: 20px !important;
  font-weight: 900 !important;
}