.profile-picture-container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-picture {
  border: 3px solid;
  border-radius: 50%;
  transition: filter 0.3s ease;
  margin-bottom: 30px;
}

.profile-picture-title {
  margin-bottom: 30px !important;
}

/* Responsive breakpoints */
@media (min-width: 0px) { /* xs */
  .profile-picture {
    width: 100%;
    max-width: 150px;
    height: 150px;
  }
}

@media (min-width: 600px) { /* sm */
  .profile-picture {
    max-width: 180px;
    height: 180px;
  }
}

@media (min-width: 960px) { /* md */
  .profile-picture {
    max-width: 200px;
    height: 200px;
  }
}

@media (min-width: 1280px) { /* lg */
  .profile-picture {
    max-width: 220px;
    height: 220px;
  }
}

.profile-picture-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.profile-picture-btn-zone {
  margin-top: auto;
  align-items: baseline;
  width: 100%;
  display: flex;
  justify-content: center;
}

.profile-picture-btn-inline {
  width: 100%;
}
