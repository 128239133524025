.saving-info {
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 5px;
}

/* Saving */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate-icon {
  display: inline-block;
  animation: rotate 2s linear infinite;
}

.saving-icon {
  width: 25px;
  height: 25px;
  fill: var(--color-light);
  stroke: var(--color-light);
}
