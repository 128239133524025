:root {
  --color-light: #f4f4f4;
  --zone-background-color-2: #242834;
  --color-pink: #e92163;
  --color-blue: #25a5e6;
  --color-purple: #9d44fd;
  --color-orange: #f2a624;
  --primary-color: #45d69f;
}

.documents-icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: var(--zone-background-color-2);
  border-radius: 8px;
}

.documents-icon-btn:hover {
  background-color: #232e2f;
  filter: brightness(90%);
  box-shadow: none;
}

.documents-icon {
  width: 20px;
  height: 20px;
  fill: var(--color-light);
}

.documents-deny-btn {
  width: 90px;
}

.documents-container {
  height: 100%;
  flex: 1;
}

.documents-date {
  margin-bottom: 10px !important;
}

.documents-title {
  margin-bottom: 20px !important;
}

.documents-zone {
  background-color: #1f2126;
  border-radius: 15px;
  list-style-type: none;
  padding: 15px;
}

@media (min-width: 1200px) {
  .documents-invitation-zone {
    padding: 20px;
  }
}

.documents-photo {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 2px solid;
  margin-right: 10px;
}

.documents-team-logo {
  max-width: 30px;
  margin-right: 10px;
}

.documents-no-documents {
  width: 100%;
  display: flex;
  justify-content: center;
}

.documents-list-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.documents-info {
  padding: 8px;
  background-color: var(--zone-background-color-2);
  width: 100%;
  border-radius: 8px;
  margin-top: 10px !important;
}

.documents-info-label {
  display: flex;
}

.documents-info-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.documents-requested {
  fill: var(--color-pink);
  color: var(--color-pink) !important;
}

.documents-approved {
  fill: var(--primary-color);
  color: var(--primary-color) !important;
}

.documents-pending {
  fill: var(--color-orange);
  color: var(--color-orange) !important;
}

.documents-rejected {
  fill: var(--color-pink);
  color: var(--color-pink) !important;
}

.documents-status {
  margin-left: auto !important;
  margin-bottom: 10px !important;
}

.documents-status-icon {
  width: 20px;
  height: 20px;
}

.documents-btn {
  margin: 2px !important;
}

.documents-btn-update {
  background-color: var(--color-pink) !important;
}