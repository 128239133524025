.stats-content {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.lineup-bench-content-inner {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.lineup-bench-divider {
  height: 1px;
  width: 100%;
  background-color: var(--color-dark);
  border-radius: 50px;
  margin-top: 8px;
  align-self: center;
}

.lineup-bench-content-inner:not(:last-child) {
  margin-bottom: 8px;
}

.lineup-select {
  width: 150px !important;
  background-color: var(--zone-background-color) !important;
}