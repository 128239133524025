.rbc-month-view, .rbc-time-view {
  border-radius: 12px;
  overflow: hidden;
  color: #fff;
  background-color: #1f2126; /* Couleur de fond globale du calendrier */
}

.rbc-month-view .rbc-date-cell.rbc-off-range {
  background-color: #333333; /* Couleur de fond des jours hors du mois courant */
}

.rbc-date-cell {
  padding-top: 3px;
  margin-bottom: 3px;
}

.rbc-time-slot {
  z-index: 100;
  cursor: pointer;
}

.rbc-time-slot:hover {
  background-color: #313131;
}

.rbc-today {
  background-color: #646979 !important; /* Couleur du jour actuel */
}

.rbc-header.rbc-today {
  background-color: #242834 !important;
  filter: brightness(200%);
}

.rbc-event {
  background-color: #e92163 !important; /* Couleur des événements */
  color: #fff; /* Couleur du texte des événements */
  border-radius: 4px; /* Bordures arrondies des événements */
  z-index: 1000 !important;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: none !important;
}

.rbc-event:hover {
  filter: brightness(90%);
}

.rbc-header {
  background-color: #25a5e6 !important;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border: none !important;
}

.rbc-allday-cell {
  display: none;
}

.rbc-events-container {
  border: 0px;
}

.rbc-off-range {
  background-color: transparent !important;
  filter: brightness(120%);
}

.rbc-off-range-bg {
  background-color: #242834 !important;
  filter: brightness(120%);
}

.rbc-button-link {
  padding: 3px;
  border-radius: 50%;
  z-index: 1000;
}

.rbc-button-link:hover {
  filter: brightness(90%);
}

.rbc-now .rbc-button-link {
  background-color: #45d69f;
}

.rbc-time-content {
  border: 0px;
}

.arrow {
  fill: #f4f4f4;
  width: 35px;
  height: 35px;
  padding: 4px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
}

.arrow:hover {
  filter: brightness(90%);
  background-color: #232e2f;
}

.right-arrow {
  transform: rotate(180deg);
}

.rbc-day-bg, .rbc-date-cell, .rbc-off-range-bg {
  background: none !important;
  border: none !important;
}

.rbc-month-view .rbc-event {
  display: none !important;
}

.rbc-header {
  border: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.rbc-month-view .rbc-date-cell {
  color: transparent; /* Cache les chiffres */
  pointer-events: none; /* Désactive les clics sur les cellules de date */
}

/* Masquer les événements dans la vue mois */
.rbc-month-view .rbc-event .rbc-row-content {
  display: none !important;
}

.rbc-month-view .rbc-row-content {
  display: none !important;
}

.rbc-month-row {
  display: inline-table !important;
  flex: 0 0 0 !important;
  min-height: 120px !important;
}