.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

/* Animation pour le fondu en sortie */
.fade-out {
  animation: fadeOut 0.5s ease-in-out;
}

/* Cléframes pour l'animation de fondu en entrée */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Cléframes pour l'animation de fondu en sortie */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Pour éviter d'afficher l'élément pendant la transition */
.hidden {
  display: none;
}
