:root {
  --color-light: #f4f4f4;
  --menu-background-color: rgba(28, 30, 33, 1);
  --menu-label-color: rgba(242, 242, 242, 1);
  --menu-label-active-color: rgba(69, 214, 159, 1);
  --menu-label-active-background-color: rgba(69, 214, 159, 0.07);
  --menu-width: 250px;
  --menu-label-padding-horizontal: 8px;
  --menu-label-padding-vertical: 8px;
  --color-primary: #45d69f;
  --color-pink: #e92163;
}
.menu-root {
  display: flex;
}

.menu-drawer {
  flex-shrink: 0;
  background-color: var(--menu-background-color);
  width: 0;
}

.menu-icon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  fill: var(--color-light);
  stroke: var(--color-light);
}

.menu-icon-focus {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  fill: var(--color-primary);
  stroke: var(--color-primary);
}

.menu-drawer-zone {
  flex-shrink: 0;
  background-color: var(--menu-background-color);
  width: var(--menu-width);
}

.menu-team {
  padding-top: var(--menu-label-padding-vertical);
  padding-bottom: var(--menu-label-padding-vertical);
  padding-right: var(--menu-label-padding-horizontal);
  padding-left: var(--menu-label-padding-horizontal);
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1000000;
}

.menu-team:hover {
  filter: brightness(90%);
}

.menu-team-logo-zone {
  height: 30px;
  width: 30px;
  text-align: center;
  margin-right: 3px;
}

.menu-team-logo {
  height: 100%;
  width: auto;
}

.menu-chevron-icon {
  height: 16px;
  width: 16px;
  fill: var(--color-light);
  stroke: var(--color-light);
  margin-left: auto;
}

.menu-title-zone {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 10px;
  text-decoration: none;
  color: var(--menu-label-color);
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  padding-left: var(--menu-label-padding-horizontal);
  padding-right: var(--menu-label-padding-horizontal);
  padding-top: var(--menu-label-padding-vertical);
  padding-bottom: var(--menu-label-padding-vertical);
}

.menu-title-zone:not(:last-child) {
  margin-bottom: 5px;
}

.menu-title-zone:hover,
.menu-title-zone:visited {
  background-color: var(--menu-label-active-background-color);
}

.menu-title-zone-focus {
  color: var(--menu-label-active-color);
  background-color: var(--menu-label-active-background-color);
}

.menu-subtitle-zone {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.menu-drawer-container {
  overflow: auto;
}

.menu-divider {
  margin: 5px 0 !important;
  background-color: var(--menu-background-color) !important;
  height: 2px !important; 
}

.menu-right-icon {
  width: 14px;
  height: 14px;
  fill: var(--color-light);
}

.menu-icon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  fill: var(--color-light);
  stroke: var(--color-light);
}

.menu-icon-focus {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  fill: var(--color-primary);
  stroke: var(--color-primary);
}

.menu-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  min-width: 18px;
  height: 10px;
  border-radius: 20px;
  background-color: var(--color-pink);
  text-align: center;
  color: white;
  font-size: 13px;
  font-weight: 700;
}

.menu-badge-zone {
  position: absolute;
  right: 22px;
}

.menu-badge-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-badge-plus {
  position: absolute;
  font-size: 12px;
  top: -3px;
  right: -7px;
}

.menu-btn-wrapper {
  padding-top: var(--menu-label-padding-vertical);
  padding-bottom: var(--menu-label-padding-vertical);
  padding-right: var(--menu-label-padding-horizontal);
  padding-left: var(--menu-label-padding-horizontal);
}

.menu-btn {
  width: 100%;
}