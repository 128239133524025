/* Couleurs approximatives basées sur les manipulations Sass */
:root {
  /* Couleurs de base */
  --color1: #e1c072;
  --color2: #fdeaa7;
  --color3: #725b16;

  /* Équivalents "darken" et "lighten" pour background et bordures */
  --color1-dark-10: #c8a458;  /* darken(#e1c072, 10%) */
  --color1-dark-30: #957836;  /* darken(#e1c072, 30%) */
  --color1-dark-50: #4d3614;  /* darken(#e1c072, 50%) */
  --color1-dark-80: #1d1500;  /* darken(#e1c072, 80%) */

  --color2-dark-10: #e6d38f;  /* darken(#fdeaa7, 10%) */
  --color2-light-7: #fff6d0;  /* lighten(#fdeaa7, 7%) */

  --card-width: 240px;
  --card-height: calc(var(--card-width) * 1.40);
}

.card-container {
  position: relative;
  width: var(--card-width);
  height: var(--card-height);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.card-clickable {
  cursor: pointer;
}

.card-clickable:hover {
  filter: brightness(90%);
}

.card-container img {
  pointer-events: none;
  -webkit-user-drag: none;
}

.card-container a {
  position: absolute;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
  left: 0;
  right: 0;
  outline: 0;
  margin: 0 auto;
  font-size: 20px;
  text-transform: uppercase;
  width: 268px;
  text-decoration: none;
  padding: 10px 0;
  text-align: center;
  z-index: 2;
  border: 2px solid var(--background-color);
  background: transparent;
  color: #a67c2b; /* darken(#e1c072, 50%) approximatif, ou adaptez */
  font-weight: 700;
  transform: scale(1);

  /* Mini gradient de fond (10% -> 20% plus sombre que color1) */
  background: radial-gradient(ellipse at center, #d9b669 0%, #c0a05c 100%);
}

.card-container a:hover {
  margin-top: -5px;
}

.card-container a.active {
  transform: rotateX(-180deg);
  opacity: 0;
}

.default-image {
  fill: var(--color-dark);
  width: 100%;
  height: calc(100% - 3px);
}

.card-skeleton{
  width: var(--card-width) !important;
  height: var(--card-height) !important;
  clip-path: url(#cardShape);
}

#card {
  position: relative;
  color: var(--color3);
  width: var(--card-width);
  height: var(--card-height);
  clip-path: url(#cardShape);
  background-color: var(--color-primary);
}

.card-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color3);
  width: calc(var(--card-width) - 8px);
  height: calc(var(--card-height) - 8px);
  clip-path: url(#cardShape);
}

/* Responsiveness */
@media (max-width: 768px) {
  :root {
    --card-width: 220px;
  }
}

@media (max-width: 480px) {
  :root {
    --card-width: 210px;
  }
}

#card #card-inner {
  position: absolute;
  top: 0;
  left: 0;
  background: #aaa;
  height: 100%;
  width: 100%;
}

/* Top Card */
#card #card-inner #card-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45%;
  background: var(--background-color);
  overflow: hidden;

  /* Dégradé linéaire (color2 -> darken(color1, 10%)) */
  background: linear-gradient(
    135deg,
    var(--background-color) 0%,
    var(--background-color) 100%
  );
}

#card #card-inner #card-top .backfont {
  position: absolute;
  bottom: -12px;
  line-height: 1;
  font-size: 100px;
  left: 0;
  letter-spacing: -5px;
  opacity: 0;
  font-weight: 700;
  font-style: italic;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1) 0.4s;
}

#card #card-inner #card-top .backfont-default {
  position: absolute;
  bottom: 2px;
  line-height: 1;
  font-size: 80px;
  left: 0;
  letter-spacing: -5px;
  opacity: 0;
  font-weight: 700;
  font-style: italic;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1) 0.4s;
  line-height: 0.7;
}

#card #card-inner #card-top .image {
  position: absolute;
  right: 10px;
  bottom: 0;
  z-index: 2;
  height: 86%;
  width: 60%;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  transition: 0.5s all ease-out 3s;
}

.user-image {
  width: 120px;
  height: 120px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--dark-color);
}

.user-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#card #card-inner #card-top .info {
  position: absolute;
  left: 0;
  bottom: 15px;
  z-index: 3;
  height: 75%;
  box-sizing: border-box;
  padding: 0 20px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#card #card-inner #card-top .info-coach {
  justify-content: flex-start;
  top: 30px;
}

#card #card-inner #card-top .info div {
  position: relative;
  display: flex;
  justify-content: center;
  line-height: 1;
  letter-spacing: -1px;
  font-size: 20px;
  opacity: 0;
  top: 20px;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}

#card #card-inner #card-top .info div.value {
  font-size: 34px;
}

#card #card-inner #card-top .info div.position {
  transition-delay: 1.25s;
  padding-bottom: 5px;
  font-weight: 700;
  margin-bottom: 5px;
  border-bottom: 2px solid rgba(253,234,167,0.9);
}

@media (max-width: 768px) {
  #card #card-inner #card-top .image {
    height: 85%;
    width: 58%;
  }

  .user-image {
    width: 110px;
    height: 110px;
  }
}

@media (max-width: 480px) {
  #card #card-inner #card-top .image {
    height: 82%;
    width: 55%;
  }

  .user-image {
    width: 100px;
    height: 100px;
  }
}

/* Partie basse de la carte */
#card #card-inner #card-bottom {
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 55%;

  /* linear-gradient(color2, darken(color2, 10%)) => (#fdeaa7 -> #e6d38f) */
  background: var(--background-color); /* fallback #fdeaa7 */
  background: linear-gradient(135deg, var(--background-color) 0%, var(--background-color) 100%);
}

#card #card-inner #card-bottom:before {
  content: "";
  position: absolute;
  left: 0;
  top: -1px;
  width: 100%;
  height: 3px;
  background: #242834;
}

#card #card-inner #card-bottom .name {
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  opacity: 0;
  top: -5px;
  position: relative;
  transition: 0.5s all ease-out 3.2s;
  margin: 4px 0;
}

#card #card-inner #card-bottom div.club {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  width: 100%;
  margin: 5px auto;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.5s ease-out 3s, transform 0.5s ease-out 3s;
}

#card #card-inner #card-bottom div.club div {
  position: relative;
  height: 100%;
  width: 100%;
}

#card.active #card-inner #card-bottom div.club {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  #card #card-inner #card-bottom .name {
    font-size: 20px;
    margin: 3px 0;
  }

  #card #card-inner #card-bottom div.club {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  #card #card-inner #card-bottom .name {
    font-size: 20px;
    margin: 3px 0;
  }

  #card #card-inner #card-bottom div.club {
    font-size: 16px;
  }
}

/* Bottom (Start) */
.coach-position {
  font-size: 20px;
  font-weight: 700;
  margin: 8px auto;
}

#card #card-inner #card-bottom .bottom-info {
  position: relative;
  margin: 0 30px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 0px solid var(--color2);
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
  gap: 14px;
}

#card #card-inner #card-bottom .bottom-info div {
  vertical-align: top;
  display: inline-block;
  text-transform: uppercase;
  font-size: 20px;
}

#card #card-inner #card-bottom .bottom-info .stat,
#card #card-inner #card-bottom .bottom-info .coach-position {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

#card #card-inner #card-bottom .bottom-info .stat {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 4px;
}

.stat-value {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin: 0 auto;
  font-size: 21px;
}

.stat-label {
  margin: 0 auto;
  margin-top: 2px;
}

#card #card-inner #card-bottom .bottom-info .stat.attack .stat-value {
  color: var(--color-primary);
  border: 3px solid var(--color-primary);
}

#card #card-inner #card-bottom .bottom-info .stat.defense .stat-value {
  color: var(--color-blue);
  border: 3px solid var(--color-blue);
}

#card #card-inner #card-bottom .bottom-info .stat.collective .stat-value {
  color: var(--color-pink);
  border: 3px solid var(--color-pink);
}

#card #card-inner #card-bottom .bottom-info .stat.attack .stat-label {
  color: var(--color-primary);
}

#card #card-inner #card-bottom .bottom-info .stat.defense .stat-label {
  color: var(--color-blue);
}

#card #card-inner #card-bottom .bottom-info .stat.collective .stat-label {
  color: var(--color-pink);
}

#card.active #card-inner #card-bottom .bottom-info .stat,
#card.active #card-inner #card-bottom .bottom-info .coach-position {
  opacity: 1;
  transform: translateY(0);
}

#card #card-inner #card-bottom .bottom-info .stat:nth-child(1),
#card #card-inner #card-bottom .bottom-info .coach-position {
  transition-delay: 2.2s;
}
#card #card-inner #card-bottom .bottom-info .stat:nth-child(2) {
  transition-delay: 2.4s;
}
#card #card-inner #card-bottom .bottom-info .stat:nth-child(3) {
  transition-delay: 2.6s;
}

@media (max-width: 768px) {
  .coach-position {
    font-size: 16px;
  }

  #card #card-inner #card-bottom .bottom-info .stat {
    font-size: 13px;
  }

  .stat-value {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }

  #card #card-inner #card-bottom .bottom-info {
    padding-top: 8px;
  }
}

@media (max-width: 480px) {
  .coach-position {
    font-size: 16px;
  }

  #card #card-inner #card-bottom .bottom-info .stat {
    font-size: 13px;
  }

  .stat-value {
    width: 35px;
    height: 35px;
    font-size: 18px;
  }

  #card #card-inner #card-bottom .bottom-info {
    padding-top: 8px;
  }
}

/* État "active" de la carte */
#card.active {
  display: block;
  /*cursor: pointer;*/
  transform: rotate(0deg);
  opacity: 1;
}

#card.active #card-inner #card-top .image {
  right: 0;
  opacity: 1;
}

#card.active #card-inner #card-top .backfont {
  opacity: 0.1;
  left: -8px;
}

#card.active #card-inner #card-top .backfont-default {
  opacity: 0.1;
  left: -8px;
}

#card.active #card-inner #card-top .info div {
  opacity: 1;
  top: 0;
}

#card.active #card-inner #card-bottom .bottom-info {
  border-top: 4px solid var(--dark-color);
}

#card.active #card-inner #card-bottom .bottom-info:after {
  width: 25%;
}

#card.active #card-inner #card-bottom .bottom-info:before {
  height: 90%;
}

#card.active #card-inner #card-bottom .bottom-info ul li {
  opacity: 1;
  left: 0;
}

#card.active #card-inner #card-bottom .name {
  opacity: 1;
  top: 0;
}

