.stats-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--zone-background-color);
  border-radius: 25px;
  list-style-type: none;
  box-sizing: border-box;
  padding: 20px;
  width: 100px;
  min-height: 120px;
  margin: 0 5px 10px;
  text-align: center;
}

.stats-card-value {
  margin: auto;
}

.stats-card-title {
  margin-top: auto;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.stats-card-title-icon {
  height: 25px;
  width: 25px;
  fill: var(--color-light);
}
