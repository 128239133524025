.team-player-container {
  height: 100%;
}

@media (min-width: 960px) {
  .team-player-container {
    margin-left: var(--menu-width);
  }
}

.team-player-user-info {
  margin: 0 0 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.team-player-season-summary {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

.team-player-season-summary-title {
  margin-bottom: 15px;
}

.team-player-info-title {
  margin-bottom: 30px;
}

.team-player-season-summary-data-zone {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.team-player-separator {
  width: 2px;
  border-radius: 10px;
  height: 22px;
  background-color: var(--color-light);
  margin: 0 15px;
}

.team-player-profile-picture {
  border: 2px solid;
  margin-right: 20px;
  width: 120px;
  height: 120px;
}

@media (min-width: 0px) {
  .team-player-profile-picture {
    width: 120px;
    height: 120px;
  }
}

@media (min-width: 600px) {
  .team-player-profile-picture {
    width: 120px;
    height: 120px;
  }
}

@media (min-width: 768px) {
  .team-player-profile-picture {
    width: 120px;
    height: 120px;
  }
}

@media (min-width: 1200px) {
  .team-player-profile-picture {
    width: 120px;
    height: 120px;
  }
}

.team-player-user-name {
  margin-bottom: 10px;
}

.team-player-team {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.team-player-team-logo-zone {
  height: 30px;
  width: 30px;
  text-align: center;
  margin-right: 5px;
}

.team-player-team-logo {
  height: 100%;
  width: auto;
}

.team-player-position {
  margin-right: 15px;
}

.team-player-btn-container {
  width: 180px;
  margin-top: 20px;
}

.team-player-title {
  fill: var(--color-light);
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.team-player-title-icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

/* Card */
.team-player-card {
  background-color: var(--zone-background-color);
  display: block;
  width: 100%;
  overflow-x: hidden;
  border-radius: 18px;
  list-style-type: none;
  padding: 20px;
  overflow-y: hidden;
}

.team-player-card-title {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.team-player-card-title-icon {
  fill: var(--color-light);
  width: 23px;
  height: 23px;
  margin-right: 10px;
}

.team-player-more-btn {
  width: 20px;
  height: 20px;
  padding: 3px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  fill: var(--color-light);
  border-radius: 50%;
  margin-left: auto;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.team-player-more-btn:hover {
  background-color: #242526;
  box-shadow: none;
}

.team-player-chart {
  max-height: 250px;
}

.team-player-info {
  background-color: var(--color-dark);
  border-radius: 18px;
  list-style-type: none;
  padding: 20px;
  flex: 1;
}

.team-player-sub-title {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
}

/* Timeline - Start */
.team-player-timeline {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  align-items: center;
}

.team-player-timeline-label {
  flex-shrink: 0;
}

.team-player-timeline-label-left {
  margin-right: 8px;
}

.team-player-timeline-label-right {
  margin-right: 8px;
}

.team-player-timeline-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid;
  border-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  flex-shrink: 0;
}

.team-player-timeline-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--color-primary);
}

.team-player-timeline-line {
  max-width: 150px;
  width: 100%;
  height: 3px;
  border-radius: 3px;
  background-color: var(--color-primary);
}
/* Timeline - End */

/* Cross Btn - Start */
.team-player-cross-btn {
  width: 10px;
  height: 10px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  stroke: var(--color-light);
  border-radius: 50%;
  background-color: var(--color-pink);
  margin-left: auto;
  margin-top: -15px;
  margin-right: -5px;
}

.team-player-cross-btn:hover {
  filter: brightness(90%);
}
/* Cross Btn - End */