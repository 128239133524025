:root {
  --color-light: #f4f4f4;
  --textfield-color: #e92163;
  --color-red: #fc3c2e;
  --color-yellow: #EDA247;
  --color-blue: #2a353a;
  --zone-background-color: #1f2126;
  --zone-background-color-2: #242834;
  --button-width: 100px;
}

.input {
  width: 100%;
  border-radius: 8px;
}

.root {
  color: var(--color-light); /* Remplacez par la valeur réelle */
  font-size: 16px;
  background-color: var(--textfield-color) !important;
}

.root.focusedLabel {
  color: var(--color-primary); /* Remplacez par la valeur réelle */
}

.root.erroredLabel {
  color: rgb(208, 48, 63);
}

.inputRoot {
  color: var(--color-light); /* Remplacez par la valeur réelle */
  border-radius: 8px;
}

.inputRoot::before {
  border-bottom: none !important;
}

.inputRoot:after {
  border-radius: 8px;
  border-bottom: 2px solid #45d69f;
}

.focusedLabel {
  color: #45d69f;
}

.picker-root {
  color: var(--color-light);
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}